.hotel-list-section {
    width: 100%;
    max-width: 1440px;
    height: fit-content;
    background-color: #fff;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: auto;
}

.hotel-list-section>h2 {
    text-transform: uppercase;
    text-align: center;
    font-size: 30px;
}

/* responsive */
@media (min-width: 320px) and (max-width: 480px) {
    .hotel-list-section {
        padding: 20px 0px;
    }

    .hotel-list-section>h2 {
        font-size: 25px;
        font-weight: 700;
    }
}

@media (min-width: 480px) {

    .hotel-filter-section,
    .hotel-filter-section2 {
        position: sticky;
        top: 100px;
    }
}