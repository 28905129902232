.career-section {
    width: 100%;
    max-width: 1440px;
    margin: auto;
    height: fit-content;
    /* padding: 40px 20px; */
    padding-bottom: 40px;
    background-color: #fff;
}

.career-banner {
    width: 100%;

    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 30px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%), url(../assets/bg_4.webp) no-repeat;
    background-position: center;
}

.career-content {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 0px 20px;
}

.career-content h2 {
    text-transform: uppercase;
    font-size: 30px;
}

.career-team-content {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 20px;
}

.career-team-content img,
.career-team-content p {
    width: 50%;
    border-radius: 10px;
}

.career-team-content p>a {
    background-color: #B92100;
    border-radius: 3px;
    padding: 0px 10px;
    font-weight: 700;
}

.career-team-content p>span {
    text-transform: uppercase;
    font-weight: 700;
    color: #B92100;
    /* font-size: 20px; */
}

.career-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* text-align: center; */
}

.career-form form {
    width: 100%;
    max-width: 800px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    padding: 20px 40px;
}

.career-form form h2,
.career-form form p {
    text-align: center;

}

.career-form .form-header {
    padding: 20px 0px;
    border-bottom: 2px solid #ccc;
}

.form-fullname {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.input-column {
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: start;
    gap: 5px;
}

.input-checkbox {
    width: 100%;
    display: flex;
}

.input-checkbox-flex {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    padding-left: 5px;
}

.input-checkbox-flex input {
    -ms-transform: scale(2);
    /* IE */
    -moz-transform: scale(2);
    /* FF */
    -webkit-transform: scale(2);
    /* Safari and Chrome */
    -o-transform: scale(2);
    /* Opera */
    transform: scale(1.5);
    padding: 10px;
    background-color: #b92100;
}

.input-column label:first-of-type {
    font-size: 16px;
}

.input-column label {
    font-size: 12px;
}

.input-column input {
    height: 40px;
    padding-left: 10px;
    font-size: 16px;
    border-radius: 5px;
    background-color: #E1E8F0;
    border: none;
}

.input-column input:focus,
.input-column textarea:focus {
    outline: 3px solid #b92100;
    background-color: #E1E8F0;
}

.input-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: start;
    gap: 20px;
}

.input-select {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.input-select-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.input-select-column select {
    width: 100%;
    height: 40px;
    padding-left: 5px;
    background-color: #E1E8F0;
    border: none;
    border-radius: 5px;
    font-size: 16px;
}

.input-select-column label {
    font-size: 12px;
}

.form-textarea {
    width: 100%;
}

.form-textarea textarea {
    background-color: #E1E8F0;
    max-width: 100%;
    border: none;
    border-radius: 5px;
    padding-left: 10px;
    padding-top: 10px;
    font-size: 16px;
}

.upload-label {
    width: 100%;
    height: 150px;
    background-color: #E1E8F0;
    border: 2px dashed #000;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    cursor: pointer;
}

.upload-label i {
    font-size: 50px;
}

.file-preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
}

.file-preview button {
    padding: 5px 10px;
    font-size: 15px;
    cursor: pointer;
}

.form-footer {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    border-top: 1.5px solid #ccc;
}

.form-footer button {
    font-size: 20px;
    background-color: #B92100;
    color: #fff;
    padding: 10px 20px;
    outline: none;
    border: none;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
    text-transform: uppercase;
}


/* Responsive */
@media (min-width: 320px) and (max-width: 768px) {

    .career-content {
        padding: 0px 10px;
    }

    .career-team-content {
        flex-direction: column;
    }

    .career-team-content img,
    .career-team-content p {
        width: 100%;
    }

    .career-banner {
        height: 100px;
    }

}

@media (min-width: 320px) and (max-width: 480px) {

    .career-form form {
        width: 100%;
        padding: 10px 10px;
    }

    .input-flex {
        flex-direction: column;
    }

    .input-column {
        width: 100%;
    }

    .input-select {
        flex-direction: column;
    }

    .career-form form h2 {
        font-size: 25px;
    }
}