.contact-us-section {
    width: 100%;
    max-width: 1440px;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0px;
    gap: 40px;
    background-color: #fff;
}

.contact-us-up {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding: 0px 40px;
    gap: 40px;
}

.contact-us-left {
    width: 50%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.contact-us-left form h2 {
    font-weight: 700;
}

.contact-us-left form {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
}

.contact-us-left form input,
.contact-us-left form textarea {
    width: 100%;
    height: 50px;
    background-color: #e1e8f0;
    border: none;
    outline: none;
    border-radius: 5px;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 500;
}

.contact-us-left form textarea {
    max-width: 100%;
    height: 150px;
    padding-top: 5px;
}

.contact-us-left form button {
    width: fit-content;
    height: 30px;
    background-color: #b81104;
    color: #fff;
    border: none;
    outline: none;
    font-size: 18px;
    border-radius: 5px;
    padding: 20px 10px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
}

.contact-us-right {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 20px;
}

.contact-us-right .contact-header-box h2 {
    font-weight: 700;
    text-transform: uppercase;
}

.contact-us-right .contact-header-box p {
    color: #818181;
}

.contact-us-right .contact-box {
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 10px;
}

.contact-us-right .contact-box-right {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
}

.contact-us-right .contact-box-right a {
    color: #818181;
}

.contact-us-right .contact-box i {
    font-size: 30px;
    color: #b81104;
}

.contact-us-right .contact-box h3 {
    text-transform: uppercase;
}

.contact-us-right .contact-box p {
    color: #818181;
}

.contact-us-down {
    width: 100%;
    height: 400px;
    padding: 20px;
}

.contact-us-down iframe {
    height: 100%;
    border-radius: 10px;
}

/* Responsive */
@media (min-width: 320px) and (max-width: 768px) {
    .contact-us-up {
        flex-direction: column;
        padding: 0px 20px;
    }

    .contact-us-left {
        width: 100%;
    }

    .contact-us-right {
        width: 100%;
    }

    .contact-us-down {
        height: 250px;
    }
}