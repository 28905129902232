.what-we-offer-section {
    background-color: #fff;
    max-width: 1440px;
    height: fit-content;
    margin: auto;
    padding: 40px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.what-we-offer-section>h2 {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 800;
    position: relative;
}

.what-we-offer-section>h2::after {
    content: '';
    width: 40%;
    height: 5px;
    position: absolute;
    bottom: 0;
    border-radius: 55px;
    left: 5px;
    background-color: #B92100;
}

.what-we-offer-section .what-we-offer-section-boxes {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
}

.what-we-offer-section-box {
    background-color: #fff;
    width: 180px;
    height: 180px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5px;
    margin-top: 10px;
    gap: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: all 0.2s ease-in-out;
}

.what-we-offer-section-box:hover {
    transform: translateY(-15px);
}

.what-we-offer-section-box img {
    width: 80px;
    height: 80px;
}

.what-we-offer-section-box h3 {
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
}

/* Responsive */
@media (min-width: 320px) and (max-width: 480px) {
    .what-we-offer-section {
        padding: 20px;
    }

    .what-we-offer-section h2 {
        font-size: 25px;
    }

    .what-we-offer-section .what-we-offer-section-boxes {
        gap: 20px;
    }

    .what-we-offer-section-box {
        width: 120px;
        height: 120px;
    }

    .what-we-offer-section-box img {
        width: 40px;
        height: 40px;
    }

    .what-we-offer-section-box h3 {
        font-size: 16px;
    }
}