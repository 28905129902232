.our-hotels-section {
    width: 100%;
    max-width: 1440px;
    height: fit-content;
    margin: auto;
    padding: 40px 20px;
    background-color: #fff;
}

.our-hotels-section h2 {
    font-size: 30px;
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
}

.hotels-filter-cards-section {
    width: 100%;
    height: fit-content;
    /* border: 2px solid #000; */
    display: flex;
    padding: 10px;
    gap: 10px;
}

.hotel-filter-section {
    width: 200px;
    height: fit-content;
    /* border: 2px solid #000; */
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: position 0.3s ease;
    
}

.hotel-filter-section h3 {
    text-transform: uppercase;
    color: #B92100;
}

.hotel-filter-section form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
}

.hotel-filter-section form .date-picker {
    width: 100%;
}

.hotel-filter-section form select,
.hotel-filter-section form .date-picker input {
    width: 100%;
    height: 40px;
    padding-left: 10px;
    background-color: #E2E8F0;
    border: none;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    font-weight: 500;
}

.hotel-filter-section form button {
    width: 100%;
    height: 40px;
    background-color: #B92100;
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
}

.hotel-cards-section {
    width: 100%;
    max-width: 1000px;
    height: fit-content;
    /* border: 2px solid #000; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;
    gap: 20px;
    padding: 0px;
}



/* Responsive */
@media (min-width: 320px) and (max-width: 480px) {
    .our-hotels-section {
        padding: 40px 0px;
    }

    .hotels-filter-cards-section{
        flex-direction: column;
    }

    .hotel-filter-section {
        width: 100%;
    }

    .hotel-cards-section {
        margin-top: 40px;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
    }
}

@media (min-width: 480px) {
    .hotel-filter-section,
    .hotel-filter-section2 {
        position: sticky;
        top: 100px;
    }
}