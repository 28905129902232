.popular-hotels-section {
    height: fit-content;
    width: 100%;
    max-width: 1440px;
    margin: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    gap: 30px;
}

.popular-hotels-section>h2 {
    font-size: 30px;
    font-weight: 800;
    text-transform: uppercase;
    position: relative;
    text-align: center;
}

/* .popular-hotels-section>h2::after {
    content: '';
    width: 40%;
    height: 5px;
    position: absolute;
    bottom: 0;
    border-radius: 55px;
    left: 0;
    background-color: #B92100;
} */

.popular-hotels-cards {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

@media (min-width: 320px) and (max-width: 400px) {
    .popular-hotel-swiper {
        padding: 40px 0px;
    }

    .popular-hotel-swiper .swiper-slide {
        width: 100%;
        /* height: fit-content; */
    }

    .popular-hotels-section>h2 {
        font-size: 25px;
    }
}