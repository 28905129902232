.search-modal-section {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    position: fixed;
    top: 0;
    z-index: 99;
    padding: 0px 10px;
}

.search-modal {
    width: 100%;
    max-width: 1000px;
    height: fit-content;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    /* background-color: #FFBF61; */
    position: relative;
    border-radius: 20px;
}

.find-hotels-shadow {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
}

.search-modal .hide-modal {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center
}

.find-hotels {
    position: relative;
}

.search-modal .hide-modal i {
    font-size: 40px;
    color: #B92100;
    cursor: pointer;
}

@media (min-width: 320px) and (max-width: 480px) {
    .find-hotels-shadow {
        box-shadow: none;
        border-radius: 10px;
    }

    .find-hotels-shadow>h4{
        padding: 0px;
    }

    .search-modal {
        padding: 40px 10px;
    }

    .hotel-search-comp2 {
        padding: 0px;
    }
}