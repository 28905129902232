.destination-slider-section {
    max-width: 1440px;
    background-color: #fff;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 40px 0px;
}

.destination-slider-section>h2 {
    width: fit-content;
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 800;
    position: relative;
}

.popular-hotels-section {
    padding-top: 40px;
    background-color: #fff;
}

.popular-hotels-section>h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 800;
}

.destination-slider-section>h2::after {
    content: '';
    width: 40%;
    height: 5px;
    position: absolute;
    bottom: 0;
    border-radius: 55px;
    left: 0;
    background-color: #B92100;
}

.destination-slider-swiper {
    width: 100%;
    height: fit-content;
    max-width: 1440px;
    padding: 40px 50px;
    background-color: #fff;
}

.popular-hotel-swiper {
    width: 100%;
    max-width: 1440px;
    height: fit-content;
    padding: 40px 40px;
    background-color: #fff;
}

.popular-hotel-swiper .swiper-slide {
    width: fit-content;
    margin-left: 10px;
    margin-right: 20px;
}

.swiper>h2 {
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
}

.swiper-button-next,
.swiper-button-prev {
    width: 35px;
    height: 55px;
    font-size: 10px;
    color: #b81104;
    border-radius: 5px;
    padding: 50px 10px;
}

.destination-slider-swiper .swiper-slide {
    width: 200px;
    height: 400px;
    text-align: center;
    font-size: 18px;
    /* background-color: red;    */
    color: #fff;
    border-radius: 20px;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.swiper-overlay {
    position: relative;
    cursor: pointer;
}

.swiper-overlay .content {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 20px 20px;
}

.swiper-overlay .content h2 {
    text-transform: uppercase;
}

.swiper-overlay .content h3 {
    text-transform: uppercase;
    background-color: #B92100;
    padding: 0px 10px;
    font-size: 16px;
    border-radius: 5px;
}

.swiper-overlay {
    height: 100%;
    width: 100%;
    border-radius: 20px;
}

.swiper-overlay1 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%),
        url('../assets/mussoorie.webp') no-repeat;
    background-position: center;
    background-size: cover;
}

.swiper-overlay2 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%),
        url('../assets/nainital.webp') no-repeat;
    background-position: center;
    background-size: cover;
}

.swiper-overlay3 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%),
        url('../assets/corbett.webp') no-repeat;
    background-position: center right;
    background-size: cover;
}

.swiper-overlay4 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%),
        url('../assets/manali.webp') no-repeat;
    background-position: bottom center;
    background-size: cover;
}

.swiper-overlay5 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%),
        url('../assets/bhimtal.webp') no-repeat;
    background-position: bottom center;
    background-size: cover;
}

.swiper-overlay6 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%),
        url('../assets/dharamshala.webp') no-repeat;
    background-position: bottom center;
    background-size: cover;
}

.swiper-overlay7 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%),
        url('../assets/amritsar.webp') no-repeat;
    background-position: center;
    background-size: cover;
}

.swiper-overlay8 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%),
        url('../assets/kausani.webp') no-repeat;
    background-position: bottom center;
    background-size: cover;
}

.swiper-overlay9 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%),
        url('../assets/ranikhet.webp') no-repeat;
    background-position: center;
    background-size: cover;
}

.swiper-overlay10 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%),
        url('../assets/dalhousie.webp') no-repeat;
    background-position: bottom center;
    background-size: cover;
}

.swiper-overlay11 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%),
        url('../assets/haridwar.webp') no-repeat;
    background-position: center;
    background-size: cover;
}

.swiper-overlay12 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%),
        url('../assets/chandigarh.webp') no-repeat;
    background-position: center;
    background-size: cover;
}

.swiper-overlay13 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%),
        url('../assets/bandhavgarh.webp') no-repeat;
    background-position: center;
    background-size: cover;
}

.swiper-overlay14 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%),
        url('../assets/shimla.webp') no-repeat;
    background-position: center;
    background-size: cover;
}

.swiper-overlay15 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%),
        url('../assets/rishikesh.jpeg') no-repeat;
    background-position: center center;
    background-size: cover;
}

.swiper-overlay16 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%),
        url('../assets/almora.webp') no-repeat;
    background-position: left;
    background-size: cover;
}

.swiper-overlay17 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%),
        url('../assets/dhanaulti.webp') no-repeat;
    background-position: center;
    background-size: cover;
}

.swiper-overlay18 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%),
        url('../assets/lohaghat.webp') no-repeat;
    background-position: center;
    background-size: cover;
}

.swiper-overlay19 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%),
        url('../assets/auli.webp') no-repeat;
    background-position: bottom center;
    background-size: cover;
}
.swiper-overlay20 {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%),
        url('../assets/new-delhi.jpg') no-repeat;
    background-position: top center;
    background-size: cover;
}

/* Responsive */
@media (min-width: 320px) and (max-width: 480px) {

    .swiper-button-next,
    .swiper-button-prev {
        color: transparent;
    }

    .destination-slider-section>h2 {
        font-size: 25px;
    }

    .popular-hotel-swiper {
        padding: 40px 10px;
    }
}