.hero-section {
    width: 100%;
    height: 100vh;
    max-width: 1440px;
    margin: auto;
    /* background-image: url("../assets/hero/hero-bg.jpg"); */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.65) 100%),
        url('../assets/hero/hero-bg3.webp') no-repeat;
    height: 100vh;
    background-size: cover;
    background-position: bottom center;
    position: relative;
}

.overlay {
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 0px 20px;
}

.overlay>h2 {
    font-size: 50px;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    font-family: "Monserrate", sans-serif;
}

.find-hotels {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 2px solid #fff; */
    margin-top: 20px;
}

.find-hotels>h4 {
    font-size: 16px;
    font-weight: 500;
    align-self: flex-start;
    color: #B92100;
    background-color: #fff;
    border-radius: 5px 5px 0px 0px;
    padding: 0px 10px;
}

.find-hotels .hotel-search-comp {
    width: 100%;
    height: 80px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 10px 10px 10px;
    padding: 10px;
    gap: 10px;
}

.find-hotels .hotel-search-comp select {
    border: none;
    outline: none;
    background-color: #E2E8F0;
    height: 100%;
    width: 40%;
    border-radius: 10px;
    padding: 0px 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
}

.find-hotels .hotel-search-comp button {
    width: 20%;
    height: 100%;
    background-color: #B92100;
    color: #fff;
    text-transform: uppercase;
    border: none;
    outline: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.find-hotels .hotel-search-comp button:hover {
    background-color: #fff;
    color: #000;
    border: 2px solid #B92100;
}

.explore-hotels {
    width: 100%;
    max-width: 800px;
    margin-top: 30px;
}

.explore-hotels h3 {
    display: flex;
    flex-direction: column;
    align-items: start;
    font-size: 16px;
    font-weight: 500;
}

.explore-hotels-cards {
    display: flex;
    gap: 20px;
}

.explore-hotels-cards .hotel-card {
    background-color: #fff;
    width: 90px;
    height: 90px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin-top: 10px;
    cursor: pointer;
}

.explore-hotels-cards .hotel-card h3 {
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
}

.explore-hotels-cards .hotel-card img {
    width: 50px;
    height: 50px;
}

/* Responsive */
@media (min-width: 320px) and (max-width: 480px) {
    .hero-section {
        height: 90vh;
    }

    .overlay {
        padding: 20px 15px;
    }

    .overlay>h2 {
        font-size: 30px;
        font-weight: 800;
        font-family: "Poppins", sans-serif;
    }

    .find-hotels .hotel-search-comp {
        height: fit-content;
        flex-direction: column;
    }

    .find-hotels .hotel-search-comp select,
    .find-hotels .hotel-search-comp button {
        width: 100%;
        height: 50px;
    }
}