.hotel-card {
    height: 520px;
    width: 320px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 20px;
    color: #000;
    text-align: start;
    background-color: #fff;
    position: relative;
    /* cursor: pointer; */
}

.hotel-card-image {
    height: 180px;
    border-radius: 20px 20px 0px 0px;
    overflow: hidden;
}

.hotel-card-image img {
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
    object-fit: cover;
    object-position: center center;
}

.hotel-card-image img:hover {
    transform: scale(1.2);
}

.hotel-card-content {
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* gap: 10px; */
}

.hotel-card-content-header {
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 5px;
}

.hotel-card-content-center {
    padding-top: 5px;
    padding-bottom: 5px;
}

.hotel-card-content-footer {
    border-top: 1.5px solid rgba(0, 0, 0, 0.1);
    padding-top: 5px;
    padding-bottom: 5px;
}

.hotel-card-content-header h1 {
    text-transform: uppercase;
    font-size: 20px;
}

.hotel-card-content-header>h4 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.hotel-card-content-header>h4>span {
    color: #000;
    /* color: #A0A0A0; */
    background-color: #fff;
}

.hotel-card-content-header>h4 .price-na {
    color: #B92100;
    border-radius: 5px;
}


.hotel-card-content-header>h4>span>span {
    font-size: 18px;
    color: #fff;
    background-color: #008234;
    padding: 0px 5px;
    border-radius: 5px;
}

.hotel-card-content-header>h4>span:last-of-type {
    font-size: 12px;
    color: #000;
    background-color: #FEC325;
    padding: 0px 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    cursor: pointer;
}

.hotel-card-content-header>p {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    font-weight: 600;
}

.hotel-card-content-header p:last-of-type a {
    color: #000;
    cursor: pointer;
    background-color: #FEC325;
    padding: 0px 10px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 14px;
    text-transform: uppercase;
}

.hotel-card-content-header p:last-of-type a i {
    font-size: 14px;
}

.hotel-card-content-header>p>p>span>i {
    color: #B92100;
}

.hotel-card-content-footer {
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    position: absolute;
    bottom: 10px;
    gap: 90px;
}

.hotel-card-content-footer button {
    padding: 0px 10px;
    border-radius: 5px;
    border: none;
    background-color: #B92100;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
}

.hotel-card-content-footer>h4 {
    text-transform: uppercase;
    color: #B92100;
    font-size: 18px;
    cursor: pointer;
}

@media (min-width: 320px) and (max-width: 480px) {
    .hotel-card {
        /* width: 100%; */
    }
}