

.festive-modal-section {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: start;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99;
  padding: 40px 0px;
  overflow-y: auto;
  user-select: none;
}

/* Cristmas Modal Container */
@font-face {
  font-family: "PlayfulChristmas";
  src: url("../fonts/Playful\ Christmas-Personal\ Use.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.cristmas-modal-container {
  width: 100%;
  max-width: 400px;
  height: 400px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/festive-img/republic-day.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  margin: auto;
  position: relative;
  color: #fff;
}

.hide-festive-modal {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.festive-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.cristmas-modal-container .festive-modal-content img {
    width: 60px;
    position: absolute;
    top: 20px;
}

.festive-modal-content h2 {
  font-family: "PlayfulChristmas", "Poppins";
  font-size: 50px;
  text-align: center;
  margin-bottom: 10px;
  z-index: 10;
}

.festive-modal-content ul {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.festive-modal-content ul li {
  width: fit-content;
  background-color: #B92100;
  color: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
}

.festive-modal-content p {
    text-align: center;
}

.festive-modal-content button {
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 10px;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
    color: #000;
    background-color: transparent;
    border: 1.5px solid #B92100;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.hide-festive-modal-bg {
  border-bottom-left-radius: 25px;
  padding-left: 10px;
  padding-bottom: 10px;
  right: 10px;
  top: 10px;
}

.hide-festive-modal i {
  font-size: 30px;
  color: #000;
  cursor: pointer;
}

.cristmas-bell-img {
    width: 400px;
    position: absolute;
    bottom: -100px;
}

.santa-img {
    height: 400px;
    position: absolute;
    left: -210px;
}

.offer-img {
  width: 80px;
  position: absolute;
  left: -2px;
  top: -1px;
  border-top-left-radius: 20px;
}

.offer-img2 {
  width: 120px;
  position: absolute;
  right: -45px;
  top: 65px;
  transform: rotate(18deg);
}

/* New Year Modal Container */
.newyear-modal-container {
  width: 100%;
  max-width: 400px;
  height: 400px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/festive-img/republic-day.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  margin: auto;
  position: relative;
  color: #000;
}

.newyear-modal-container .festive-modal-content img {
  width: 80px;
  position: absolute;
  border-radius: 55px;
  top: 20px;
}

.balloon1 {
  width: 100px;
  position: absolute;
  top: -80px;
  left: 50px;
  transform: rotate(30deg);
}

.balloon2 {
  width: 100px;position: absolute;
  top: -60px;
  right: 50px;
  transform: rotate(-30deg);
}


/* Flyers Modal Container */
.flyers-modal-container {
  width: 100%;
  max-width: 400px;
  height: 400px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/festive-img/satte.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  margin: auto;
  position: relative;
  color: #000;
}

.flyers-modal-container i {
  color: #B92100;
}

/* Responsive */
@media (max-width: 480px) {

    .festive-modal-section {
        padding: 0px 10px;
        padding-bottom: 100px;
    }
    
    .festive-modal-container {
      width: 320px;
      height: 320px;
    }

    .newyear-modal-container {
      padding: 20px 10px;
    }

    .flyers-modal-container {
      width: 320px;
      height: 320px;
    }

    .festive-modal-content h2 {
      font-size: 45px;
    }

    .santa-img {
        display: none;
    }

    .festive-modal-content img {
        width: 40px;
        position: absolute;
        top: 5px;
    }

    .festive-bell-img {
        width: 320px;
        position: absolute;
        bottom: -100px;
    }

    .offer-img {
      width: 60px;
    }

    .offer-img2 {
      width: 80px;
      position: absolute;
      right: -15px;
      top: 85px;
      transform: rotate(18deg);
    }
}