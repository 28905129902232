.packages-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.packages-container h1 {
  text-align: center;
  margin: auto;
}

.header {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  margin-bottom: 20px; /* Optional for spacing */
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.state-dropdown-right {
  margin-left: 20px; /* Space between title and dropdown */
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #ccc; /* Light border for better visibility */
  border-radius: 5px; /* Rounded corners */
  background-color: #f9f9f9; /* Light background */
  transition: all 0.3s ease; /* Smooth transition for hover and focus */
  cursor: pointer;
}

.state-dropdown-right:hover {
  border-color: #b92100; /* Change border color on hover */
}

.state-dropdown-right:focus {
  outline: none;
  border-color: #b92100; /* Focus border color */
}

.state-dropdown-right option {
  padding: 10px;
  font-size: 14px;
}

.packages-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

.package-card {
  width: 320px;
  height: 350px;
  cursor: pointer;
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: all 0.3s ease-in-out;
}

.package-card:hover {
  transform: scale(1.05);
}

.package-card h3 {
  text-transform: uppercase;
}

/* .package-details p {
  font-style: italic;
} */

.package-card .package-icons {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 5px;
  gap: 30px;
}

.package-card .package-icons .package-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.package-card .package-icons .package-icon span {
  text-transform: capitalize;
  font-size: 10px;
}

.package-card .package-icons .package-icon img {
  width: 20px;
}

.package-inclusive {
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.package-inclusive span i {
  color: #b92100;
}

.package-card img {
  width: 100%;
  border-radius: 5px;
}

.package-details {
  margin-top: 20px;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.back-button {
  border: none;
  outline: none;
  background-color: #fff;
  cursor: pointer;
}

.back-button img {
  width: 50px;
}

.package-details h1 {
  text-transform: uppercase;
  text-align: start;
  font-weight: 700;
}

.package-details img {
  width: 100%;
  border-radius: 10px;
}

.package-details .package-inclusive i {
  font-size: 20px;
}

.package-details .package-inclusive span {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
}

.package-details-icons {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 10px 0px;
}

.package-details-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.package-details-icons .package-details-icon img {
    width: 25px;
  }

  .see-more-button {
    margin: 10px 0px;
    padding: 5px 0px;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    background-color: #b92100;
    color: #fff;
  }

  .package-details-gallery {
    max-width: 650px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  .package-details-gallery img {
      width: 150px;
      height: auto;
      object-fit: cover;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }

    .package-details-gallery img:hover {
        transform: scale(1.05);
    }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
}

.modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
}

.modal-image {
    max-width: 800px;
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #b92100;
     background-color: #fff;
     padding: 0px 10px;
     border-radius: 55px;
    font-size: 25px;
    cursor: pointer;
}

.package-details-duration {
    margin-top: 10px;

}

.package-details-duration span{
    background-color: #b92100;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
}

.package-details-destinations h4 {
    text-transform: uppercase;
}

.package-details-destinations-container span {
    padding-left: 20px;
}

.package-details-destinations-container ul span {
    font-weight: 600;
}

.package-details-destinations-container ul li {
    margin-left: 60px;
    list-style: circle;
}

.package-details-destinations-container span {
    display: flex;
    align-items: center;
    gap: 10px;
}

.package-details-destinations-container span i{
    font-size: 18px;
}


/* Responsive */
@media (max-width: 480px) {
    .packages-container h1 {
        font-size: 25px;
    }

    .packages-container {
        padding: 10px;
    }

    .state-dropdown-right {
        margin: 0px;
        padding: 5px;
    }

    .header-content h2 {
        font-size: 18px;
    }

    .header {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .back-button img {
        width: 35px;
    }

    .packages-container h1 {
        font-size: 20px;
    }

    .packages-container p {
        font-size: 12px;
    }

    .package-details {
      padding: 10px;
    }

    .package-details .package-inclusive i {
        font-size: 15px;
    }

    .package-details .package-inclusive span {
        font-size: 16px;
    }

    .package-details-icons .package-details-icon img {
        width: 16px;
    }

    .package-details-icons .package-details-icon span {
        font-size: 12px;
    }

    .package-details-gallery img{
        width: 80px;

    }
}