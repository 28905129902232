.booking-modal-section {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: start;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99;
    padding: 40px 0px;
    overflow-y: auto;
}

.booking-modal {
    width: 100%;
    max-width: 800px;
    height: fit-content;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    /* background-color: #FFBF61; */
    position: relative;
    border-radius: 20px;
}

.hide-modal {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center
}

.hide-modal-bg {
    background-color: #fff;
    border-bottom-left-radius: 25px;
    padding-left: 10px;
    padding-bottom: 10px;
    right: 10px;
    top: 10px;
}

.hide-modal i {
    font-size: 40px;
    color: #B92100;
    cursor: pointer;
}

.booking-modal-banner img {
    width: 100%;
    border-radius: 15px 10px 0px;
}

.booking-hotel-name {
    text-transform: uppercase;
    font-weight: 800;
    color: #B92100;
    text-align: center;
}

.booking-hotel-description {
    text-align: center;
    font-size: 14px;
}

.booking-price-validity-and-contact {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 20px;
    padding-top: 20px;
    border-top: 1px solid #ccc;
}

.room-price-validity h2 {
    text-transform: uppercase;
    color: #B92100;
    font-size: 18px;
    text-align: center;
}

.room-price-validity {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1.5px solid #000; */
    /* border: 2px solid black; */
    
}

.room-price-validity-left {
    border-right: 1.5px solid #B92100;
    padding: 0px 10px;
}

.booking-contact-details {
    width: 40%;
    /* border: 2px solid black; */

}

.price-validity-date {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 10px;
}

.price-validity-date span:nth-of-type(1),
.price-validity-date span:nth-of-type(2) {
    background-color: #B92100;
    color: #fff;
    padding: 0px 5px;
    border-radius: 5px;
}

.room-prices-list span span{
    background-color: #008234;
    color: #fff;
    border-radius: 5px;
    padding: 0px 5px;
}

.booking-contact-details h2{
    font-size: 18px ;
    color: #B92100;
    text-align: start;
    text-transform: uppercase;
}

.booking-contacts a{
    color: #000;
}

.booking-contacts a i {
    color: #B92100;
}

/* Responsive */
@media (min-width: 320px) and (max-width: 480px) {
    .booking-modal-section {
        padding: 20px 10px;
        align-items: center;
    }

    .booking-modal {
        margin-top: 50px;
    }

    .booking-price-validity-and-contact {
        flex-direction: column;
    }

    .room-price-validity {
        width: 100%;
    }

    .room-price-validity-left:last-of-type {
        border: none;
    }

    .booking-contacts {
        text-align: center;
    }

    .booking-contact-details {
        width: 100%;
        align-items: center;
    }

    .booking-contact-details h2 {
        text-align: center;
    }

    .booking-modal {
        padding: 10px;
    }
}