.only-hotels-section {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0px;
}

.only-hotels-section>h2 {
    font-weight: 800;
    font-size: 30px;
    text-transform: uppercase;
}

@media (min-width: 480px) {
    .hotel-filter-section2 {
        position: sticky;
        top: 80px;
    }
}