.hotel-destination-card {
    width: 320px;
    height: 420px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 20px;
    position: relative;
}

.hotel-destination-card-image {
    width: 100%;
    height: 200px;
    border-radius: 20px 20px 0px 0px;
    overflow: hidden;
}

.hotel-destination-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px 20px 0px 0px;  
    transition: all 0.3s ease-in-out; 
}

.hotel-destination-card-image img:hover {
    transform: scale(1.2);
}

.hotel-destination-card-content {
    /* padding: 10px; */
}

.hotel-destination-card-content-header h1 {
    text-transform: uppercase;
    font-size: 20px;
    padding: 0px 10px;
}

.hotel-destination-card-content-header p {
    border-top: 1.5px solid rgba(0, 0, 0, 0.1);
    padding: 5px 0px;
    color: #4d4d4d;
    font-size: 15px;
    font-weight: 400;
    padding: 0px 10px;
}

.hotel-destination-card-content-footer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 90px;
    position: absolute;
    bottom: 10px;
    border-top: 1.5px solid rgba(0, 0, 0, 0.1);
    padding: 0px 5px;
    padding-top: 5px;
}

.hotel-destination-card-content-footer h3 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: #B92100;
}

.hotel-destination-card-content-footer button {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #B92100;
    color: #fff;
    padding: 0px 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
}

@media (min-width: 320px) and (max-width: 480px) {
    .hotel-destination-card {
        /* width: 100%; */
    }

    .hotel-destination-card-content-footer {
        /* justify-content: space-between; */
        /* gap: 100px; */
    }
}