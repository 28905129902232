.why-choose-us-section {
    max-width: 1440px;
    margin: auto;
    background-color: #fff;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    gap: 30px;
}

.why-choose-us-section>h2 {
    font-size: 30px;
    font-weight: 800;
    text-transform: uppercase;
    position: relative;
}

.why-choose-us-section>h2::after {
    content: '';
    width: 40%;
    height: 5px;
    position: absolute;
    bottom: 0;
    border-radius: 55px;
    left: 5px;
    background-color: #B92100;
}

.why-choose-us-contents {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 40px;
}

.why-choose-us-section .why-choose-us-left {
    width: 50%;
}

.why-choose-us-section .why-choose-us-left img {
    width: 100%;
    border-radius: 20px;
}

.why-choose-us-section .why-choose-us-right {
    width: 50%;
}

.why-choose-us-right p span {
    font-weight: 700;
    text-transform: uppercase;
}

/* Responsive */
@media (min-width: 320px) and (max-width: 768px) {
    .why-choose-us-section {
        padding: 20px;
    }

    .why-choose-us-section h2 {
        font-size: 25px;
    }

    .why-choose-us-contents {
        flex-direction: column;
        gap: 20px;
    }

    .why-choose-us-section .why-choose-us-left,
    .why-choose-us-section .why-choose-us-right {
        width: 100%;
    }
}